.print-btn a{
    padding: 10px 40px;
    background: linear-gradient(to right, #00d2ff 0%, #3a7bd5 51%, #00d2ff 100%);
    border-radius: 5px;
    color:#fff;
    text-decoration: none;
    display: inline-block;
}
.report-img{
    padding: 60px 0px;
}

.report-data {
  
    background-color: #0f368a;
}

.report-content h1{
    font-size: 180px;
    font-weight: 700;
    color:#fff;
}

.border-img img{
    width: 100%;
}





.avatar-sec h5{
    margin-left: 70px;
}


.number-name{
    position: absolute;
    bottom: 0%;

}

.avatar-sec{
    position: relative;
}

.facebook-icon{
    width: 180px;
    height: 180px;
    line-height: 230px;
    text-align: center;
    background-color: #214c9d;
    border-radius: 50%;
    cursor: pointer;
}

.facebook-icon span{
    color:#fff;
}

.linkdin-icon{
    width: 180px;
    height: 180px;
    line-height: 230px;
    text-align: center;
    background-color: #3b84c7;
    border-radius: 50%;
    cursor: pointer;
}

.linkdin-icon span{
    color:#fff;
}


.social-icon {
    padding: 40px 0px 0px;
}

.facebook-icon{
    position: relative;
}
.facebook-icon h5 {
    position: absolute;
    top: 106%;
    left: 10%;
}

.linkdin-icon{
    position: relative;
}

.linkdin-icon h5 {
    position: absolute;
    top: 106%;
    left: 30%;
}

/** Trends Css */
.trands-sec{
    padding: 80px 0;
}

.trands-sec .trands-search button{
    background-color: var(--primary-color);
    color: #fff;
    padding: 7px 30px;
    border-radius: 30px;
    border: none;
    margin-top: 12px;
}

.trands-sec select:focus{
    box-shadow: none !important;
}
.trands-sec input:focus{
    box-shadow: none !important;
}

.tranding-hashtags-sec{
    padding: 0px 0px 60px;
}
.tranding-hashtags-sec .trands-hashtags{
    border:1px solid #000;
    border-radius: 10px;
    padding: 20px;
}

.tranding-hashtags-sec .trands-hashtags ul li {
    padding: 10px 10px;
    border-radius: 50px;
    color: #000;
    background-color: #02b4a23d;
    margin: 5px;
    cursor: pointer;
}

.tranding-post-sec{
    padding: 0px 0px 60px;
}
.tranding-post-sec .trands-post{
    border:1px solid #000;
    border-radius: 10px;
    padding: 20px;
}

.tranding-post-sec .trands-post ul li {
    color: #000;
    margin: 5px;
    margin-left: 20px;
}
