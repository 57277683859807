@media (max-width: 575.98px) {

    .navbar-toggler span {
        color: #16d489 !important;
    }

    .navbar-nav .dropdown-menu {
        position: absolute;
        left: 0px;
        top: 44px;
    }

    .uplaod-size-img{
        left: 75px;
    }

    .youtube-content{
        padding: 20px;
    }

    .deshborad-banner-content p{
        width: 100%;
    }

    .num h6 {
        font-size: 12px;
    }

    .sec-1 .save-next{
        display: block !important;
    }


    .search-container form input {
        width: 80%;
    }

    .editimg img {
        max-width: 36px !important;
    }
    
    .create_video_content .nav-item {
        margin-top: 20px;
    }
    
    .file-upload {
        cursor: pointer;
        opacity: 0;
        top: -58px;
        left: 84px;
        height: 40px;
        width: 131px;
        background: red;
        font-size: 0px;
        position: absolute;
    }


    .drag-btn a{
        transform: translate(-63px,-60px) !important;
    }

    .mdb-select.md-form{
        margin-top: 20px !important;
    }


    .deshborad-banner-content h2 {
        font-size: 30px;
       
    }
    .deshborad-banner-content h5 {
        font-size: 22px;
    }
    .navbar-nav .dropdown, .dropup {
        position: relative;
        margin-left: 32px;
        margin-bottom: 10px;
    }


    ul.navbar-nav {
        background: #093470 !important;
        position: relative !important;
        z-index: 999 !important;
    }
    .join-vidoes{
        margin-top: 20px;
    }
    .tab-content .col-md-7{
        border:none !important;
    }

    .navbar ul li {
        margin-left: 30px;
    }

    .navbar-light .navbar-toggler {
        color: transparent !important;
        border-color: transparent !important;
    }

     button:focus{
           outline: unset !important;
    }
    

    .user-data {
       margin-top: 30px;
    }

    
    .footer-menu ul li a {
        font-size: 12px !important;
    }
    .footer-copyright {
        margin-top: 20px;
        text-align: center !important;
        font-size: 12px !important;
    }
    .footer-copyright span{
        
          font-size: 12px !important;
          text-align: center !important;
    }
     .footer-img{
         margin-top: 20px;
         text-align: center;
     }
  
     .popup-sec .nav .nav-item {
        margin-top: 24px;
    }
     .deshborad-sec-2-head h5 {
        font-size: 14px;
        margin: 0px;
    }

    .deshborad-sec-2-head a{
        font-size: 14px;
    }

    .sec-1-heading li:nth-child(1)::before{
        display: none;
    }

    .fc-toolbar-chunk{
        margin-bottom: 20px;
    }
    
    .fc .fc-toolbar.fc-header-toolbar{
        flex-direction: column;
    }

     .deshborad-banner-content.text-white {
        padding: 50px 0px;
    }
    .deshborad-conunt {
        padding-top: 0px !important;
        padding-bottom: 40px;
    }

    .deshborad-box{
        margin-top: 20px;
    }
    .join-sec-content.pl-4 {
        margin-top: 40px;
    }

    .deshborad-banner-content h2::before {
    
        top: 4px;
        left: 4px;
        font-size: 30px;
        
    }

    .search-add {
        flex-direction: column;
        align-items: flex-start !important;
        margin-top: 20px;
    }
    .search-add .add {
        margin-top: 20px;
    }
   

    .personal-box {
        padding: 16px;
    }
    .myaccount-sec {
        padding: 40px 0px 40px;
    }

    .password-sec .line::before {
        top: 107px; 
    }

    .user-data ul li img{
        max-width: 50px !important;
    }

    .user-data {
     
        overflow: hidden;
        overflow-x: scroll;
    }

    .create_content_box .tagstyle li {
        padding: 1px 7px;
        border-radius: 20px;
        background-color: #bfc6cc;
        font-size: 14px;
        margin-top: 10px;
        color: var(--white);
        overflow-x: hidden;
    }

    .create_content_file_upload{
        margin-top: 20px;
    }

    .login-account form{
        margin-top: 30px;
    }

    .create_video_content span {
        margin-top: 20px !important;
    }

    .myaccount-sec .update a{
        font-size: 16px !important;
        padding: 7px 12px;
        letter-spacing: 0px !important;
    }

    .upgrades-sec h5{
        margin-top: 30px;
    }
   
    .slide-setting   input[type=number]::-webkit-inner-spin-button{
        padding: 10px;
    }
     
    .logo-box .custom-file-input {
        border-radius: 30px !important;
        width: 90%;
        background-color: #000;
        opacity: 0;
        cursor: pointer;
        font-size: 0px;
        left: 4%;
        top: -13px;
        height: 18vh;
    }

   

    .logo-position{
        min-width: 100px;
        max-width: 100px;
        margin-top: 10px;
        margin: auto;
    }


    .text-position-dropdown {
        min-width: 120px;
        max-width: 120px;
        margin: auto;
    }


    .upload-box-video .custom-file-label{
        position: absolute !important;
        left: 0% !important;
    }

    .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
        width: 100%;
    }

 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

    .navbar-toggler span {
        color: #16d489 !important;
    }

    .navbar-nav .dropdown-menu {
        position: absolute;
        left: 0px;
        top: 44px;
    }

    .editimg img {
        max-width: 36px;
    }
    .navbar-nav .dropdown, .dropup {
        position: relative;
        margin-left: 32px;
        margin-bottom: 10px;
    }

    ul.navbar-nav {
        background: #093470 !important;
        position: relative !important;
        z-index: 999 !important;
    }

    .navbar ul li {
        margin-left: 30px;
    }

    .navbar-light .navbar-toggler {
        color: transparent !important;
        border-color: transparent !important;
    }

     button:focus{
           outline: unset !important;
    }

    .youtube-content{
        padding: 20px;
    }

    .uplaod-size-img{
        top:183px;
        

    }
    

    .user-data {
       margin-top: 30px;
    }

    .footer-menu ul li a {
        font-size: 18px;
    }
    .footer-copyright {
        margin-top: 20px;
    }
    .footer-copyright span{
          font-size: 18px;
          
    }
     .footer-img{
         margin-top: 20px;
     }
     .deshborad-banner-content.text-white {
        padding: 50px 0px;
    }
    .deshborad-conunt {
        padding-top: 0px !important;
        padding-bottom: 40px;
    }

    .deshborad-box{
        margin-top: 20px;
    }
    .join-sec-content.pl-4 {
        margin-top: 40px;
    }

    .tab-content .col-md-7{
        border:none !important;
    }

    .search-add {
         display: flex !important;
        align-items: center !important;
        margin-top:30px ;
    }

    .search-container.mr-lg-3 {
        margin-right: 20px;
    }
    
    
   
   
    .login-account form{
        margin-top: 30px;
    } 
   

    .create_content_file_upload{
        margin-top: 20px;
    }


    .create_video_content span {
        margin-top: 20px ;
    }

    .quotewidth{
        width: 90%;
    }

    .quotewidth .searchicon-box{
        margin-top: 0px !important;
    }

    .list-vidoes{
        margin-top: 20px;
    }

    .join-vidoes{
        margin-top: 20px;
    }

    .training-important-sec  .col-md-4{
        display: flex !important;
        justify-content: center !important;
    }
    .upgrades-sec h5{
        margin-top: 30px;
    }
    .sec-1-heading li:nth-child(1)::before{
        display: none !important;
    }
    .sizeview{
       margin-top: 20px;
    }

    .footer-img{
        text-align: center;
    }
   
    .container {
        max-width: 700px;
    }
    .slide-setting   input[type=number]::-webkit-inner-spin-button{
        padding: 10px;
    }

    .logo-box .custom-file {
        position: absolute;
        left: 0;
        top: 0;
    }

    .logo-box .custom-file-input {
        border-radius: 30px;
        width: 88%;
        background-color: #000;
        opacity: 0;
        cursor: pointer;
        font-size: 0px;
        left: 9% !important;
        top: 4px !important;
        height: 8vh ;
    }

    .logo-box .custom-file-input {
        border-radius: 30px;
        width: 100%;
        background-color: #000;
        opacity: 0;
        cursor: pointer;
        font-size: 0px;
        left: 9% !important;
        top: 4px !important;
        height: 8vh;
    }


    
    .text-position-dropdown {
        min-width: 125px;
        max-width: 125px;
        margin-top: 10px;
    }

    .logo-position{
        
        min-width: 100px;
        max-width: 100px;
        margin-top: 10px;
    }


    .sec-1 .mobilestrem  .search-add  {
        display: block !important;
        
    }

    .sec-1 .mobilestrem .search-container {
        margin-top: 10px;
    }
    .sec-1 .mobilestrem .add{
        margin-top: 10px;
    }

    .imgcreator-sec .row .col-lg-8{
        display: block !important ;
    }

    .imgcreator-sec .row .col-lg-4{
        display: block !important;
        margin-top: 20px !important;
    }

    .imgcreator-sec .nav a{
        padding: 10px 35px !important;
    }

    .sec-1 .mobilestrem {
        display: flex !important;
        justify-content: space-around !important;
    }
   

    .logo-position{
        
        min-width: 100px;
        max-width: 100px;
        margin-top: 10px;
        margin: auto;
    }

    .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
        width: 100%;
    }



 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {

    .navbar-toggler span {
        color: #16d489 !important;
    }

    .navbar-nav .dropdown-menu {
        position: absolute;
        left: 0px;
        top: 44px;
    }
     
  
    .logo-box .custom-file-input {
        border-radius: 30px;
        width: 88%;
        background-color: #000;
        opacity: 0;
        cursor: pointer;
        font-size: 0px;
        left: 9%;
        top: 4px;
        height: 8vh;
    }
    
       
    .logo-box .custom-file {
        position: absolute;
        left: 0px;
        width: 100%;
        height: 100%;
        top: 0;
    }
          
        

   

    .navbar-nav .dropdown, .dropup {
        position: relative;
        margin-left: 32px;
        margin-bottom: 10px;
    }

    ul.navbar-nav {
        background: #093470 !important;
        position: relative !important;
        z-index: 999 !important;
    }

    .navbar ul li {
        margin-left: 30px;
    }

    .navbar-light .navbar-toggler {
        color: transparent !important;
        border-color: transparent !important;
    }

     button:focus{
           outline: unset !important;
    }
    

    .user-data {
       margin-top: 30px;
    }

    .footer-menu ul li a {
        font-size: 16px;
    }
    .footer-copyright {
        margin-top: 20px;
    }
    .footer-copyright span{
          font-size: 16px;
          
    }
     .footer-img{
         margin-top: 20px;
     }
     .deshborad-banner-content.text-white {
        padding: 50px 0px;
    }
    .deshborad-conunt {
        padding-top: 0px !important;
        padding-bottom: 40px;
    }

    .deshborad-box{
        margin-top: 20px;
    }
    .join-sec-content.pl-4 {
        margin-top: 40px;
    }
   
    .upgrades-sec h5{
        margin-top: 30px;
    }

  
    .search-add .add a {
        padding: 7px 12px;
    }
    .create_content_file_upload{
        margin-top: 20px;
    }
    .search-add .add a {
        padding: 7px 6px;
    }
   
    .login-account form{
        margin-top: 30px;
    }
    
    .join-vidoes{
        width: 100% !important;
    }

    .tab-content .col-md-7{
        border:none !important;
    }
    .training-img {
        margin-top: 50px;
    }

    .sec-1-heading li:nth-child(1)::before{
        display: none !important;
    }

    .footer-img {
        margin-top: 20px;
        text-align: center;
    }


    .text-position-dropdown {
        min-width: 125px;
        max-width: 125px;
        margin-top: 10px;
    }

    .imgcreator-sec .row .col-lg-8{
        display: block !important ;
    }

    .imgcreator-sec .row .col-lg-4{
        display: block !important;
        margin-top: 20px !important;
    }

    .imgcreator-sec .nav a{
        padding: 10px 35px !important;
    }

    .sec-1 .mobilestrem {
        display: flex !important;
        justify-content: space-around !important;
    }

    .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
        width: 100%;
    }



 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .search-add .add a {
        padding: 7px 6px;
    }

    .logo-head.text-white h5{
     font-size: 18px;
    }
    .navbar-light .navbar-toggler{
        border-color: transparent !important;
    }
    .navbar-toggler span {
        color: #16d489;
    }
    ul.navbar-nav {
        background: #093470!important;
        position: relative!important;
        z-index: 999!important;
        padding: 10px;
    }
    
    .nav-pills .nav-link{
        margin: 2px;
    }
   
    .join-vidoes{
        width: 100% !important;
    }

    .sec-1-heading li:nth-child(1)::before{
        display: none !important;
    }

    .training-img {
        margin-top: 50px;
    }

    .navbar ul li a{
        font-size: 14px !important;
    }
    .navbar ul li{
        margin-right: 10px !important;
    }

    .sec-1 .sec-1-heading li{
        font-size: 14px !important;
    }

    .text-position-dropdown {
        min-width: 125px;
        max-width: 125px;
        margin-top: 10px;
    }

    .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
        width: 130px;
    }

    .logo-position{
        
        min-width: 100px;
        max-width: 100px;
        margin-top: 10px;
        margin: auto;
    }



 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .logo-box .custom-file-input{
        height: 15vh;
    }

 


 }


 @media (min-width: 1399px) { 

 .imgcreator-sec .col-lg-8{
     display:unset !important;
     
 }

 .imgcreator-sec .col-lg-4{
    display:unset !important;
    
}

.creator-box{
    padding: 20px 30px;
}

.logo-position .box{
    width: 34px;
}

.logo-box .custom-file-input{
    height: 7vh;
}





@media (min-width: 1399px) {




    .uplaod-size-img{
        left: 240px;
    }


    .text-position-dropdown {
        min-width: 125px;
        max-width: 125px;
        margin-top: 10px;
    }


    


    .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
        width: 127px;
    }

     

    .upload-box-video .custom-file-label{
        height: 8vh;
    }

    .logo-box .custom-file-input{
        height: 7vh;
    }

    .upload-border .custom-file-input-audio{
        height: 9vh;
    }



}






 }
  